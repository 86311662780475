import fetchApi from 'cross-fetch';
import {
  AliasControllerApi,
  ApiAccountControllerApi,
  ConnectorControllerApi,
  ApiSsoControllerApi,
  AttachmentControllerApi,
  BounceControllerApi,
  CommonActionsControllerApi,
  Configuration,
  ContactControllerApi,
  DomainControllerApi,
  EmailControllerApi,
  EmailVerificationControllerApi,
  ExpiredControllerApi,
  ExportControllerApi,
  GroupControllerApi,
  InboxControllerApi,
  InboxForwarderControllerApi,
  InboxReplierControllerApi,
  InboxRulesetControllerApi,
  IntegrationsControllerApi,
  MailServerControllerApi,
  MissedEmailControllerApi,
  OrganizationApi,
  PhoneControllerApi,
  SentEmailsControllerApi,
  SmsControllerApi,
  SubscriptionApi,
  TemplateControllerApi,
  TrackingControllerApi,
  WebhookControllerApi,
  type ConfigurationParameters,
  type ErrorContext,
  GuestPortalControllerApi,
} from '@mailslurp/admin-sdk';

import { getLogger } from '../services/getLogger';

export function getAdminClient(apiKey: string, basePath: string): AdminClient {
  return new AdminClient(apiKey, basePath);
}

const log = getLogger('adminclient');

export class AdminClient {
  public readonly __confRef: ConfigurationParameters;
  public readonly portalApi: GuestPortalControllerApi;

  public readonly validateApi: EmailVerificationControllerApi;
  public readonly inboxReplierApi: InboxReplierControllerApi;
  public readonly accountApi: ApiAccountControllerApi;
  public readonly connectorApi: ConnectorControllerApi;
  public readonly commonApi: CommonActionsControllerApi;
  public readonly subscriptionApi: SubscriptionApi;
  public readonly bounceApi: BounceControllerApi;
  public readonly organizationsApi: OrganizationApi;
  public readonly domainApi: DomainControllerApi;
  public readonly expiredApi: ExpiredControllerApi;
  public readonly inboxApi: InboxControllerApi;
  public readonly sentEmailApi: SentEmailsControllerApi;
  public readonly emailApi: EmailControllerApi;
  public readonly smsApi: SmsControllerApi;
  public readonly phoneApi: PhoneControllerApi;
  public readonly missedEmailApi: MissedEmailControllerApi;
  public readonly ssoApi: ApiSsoControllerApi;
  public readonly exportApi: ExportControllerApi;
  public readonly webhookApi: WebhookControllerApi;
  public readonly templateApi: TemplateControllerApi;
  public readonly integrationApi: IntegrationsControllerApi;
  public readonly contactApi: ContactControllerApi;
  public readonly groupApi: GroupControllerApi;
  public readonly aliasApi: AliasControllerApi;
  public readonly attachmentApi: AttachmentControllerApi;
  public readonly mailServerApi: MailServerControllerApi;
  public readonly inboxRulesetApi: InboxRulesetControllerApi;
  public readonly inboxForwarderApi: InboxForwarderControllerApi;
  public readonly trackingApi: TrackingControllerApi;

  constructor(apiKey: string, basePath: string) {
    // can set API_URL process env (see nuxt.config.js) to use staging api etc
    const configArgs: ConfigurationParameters = {
      apiKey,
      basePath,
      fetchApi,
      headers: { 'x-client': 'x_client_ms2' },
      middleware: [
        {
          async onError(context: ErrorContext) {
            const error = context.error as Error;
            if (context.response) {
              return context.response;
            } else if (error) {
              log('onError adminClient');
              console.error(error.stack);
              const info = `${context.url}`;
              throw new Error(`${error.message}\n${info}`);
            }
          },
        },
      ],
    };
    const config = new Configuration(configArgs);
    // create controllers
    this.inboxReplierApi = new InboxReplierControllerApi(config);
    this.exportApi = new ExportControllerApi(config);
    this.sentEmailApi = new SentEmailsControllerApi(config);
    this.missedEmailApi = new MissedEmailControllerApi(config);
    this.attachmentApi = new AttachmentControllerApi(config);
    this.inboxRulesetApi = new InboxRulesetControllerApi(config);
    this.commonApi = new CommonActionsControllerApi(config);
    this.aliasApi = new AliasControllerApi(config);
    this.validateApi = new EmailVerificationControllerApi(config);
    this.accountApi = new ApiAccountControllerApi(config);
    this.connectorApi = new ConnectorControllerApi(config);
    this.subscriptionApi = new SubscriptionApi(config);
    this.portalApi = new GuestPortalControllerApi(config);
    this.bounceApi = new BounceControllerApi(config);
    this.expiredApi = new ExpiredControllerApi(config);
    this.organizationsApi = new OrganizationApi(config);
    this.emailApi = new EmailControllerApi(config);
    this.smsApi = new SmsControllerApi(config);
    this.phoneApi = new PhoneControllerApi(config);
    this.inboxApi = new InboxControllerApi(config);
    this.domainApi = new DomainControllerApi(config);
    this.webhookApi = new WebhookControllerApi(config);
    this.templateApi = new TemplateControllerApi(config);
    this.contactApi = new ContactControllerApi(config);
    this.groupApi = new GroupControllerApi(config);
    this.mailServerApi = new MailServerControllerApi(config);
    this.integrationApi = new IntegrationsControllerApi(config);
    this.trackingApi = new TrackingControllerApi(config);
    this.inboxForwarderApi = new InboxForwarderControllerApi(config);
    this.ssoApi = new ApiSsoControllerApi(config);
    // expose a readonly config for testing
    this.__confRef = configArgs;
  }
}
