import {
  type AbstractWebhookPayload,
  type AccountDto,
  type AddPhoneNumberOptions,
  type AddPhonePlanOptions,
  type AliasDto,
  type AliasThreadProjection as ThreadProjection,
  type AllQuotesDto,
  type AttachmentMetaData,
  type BillingPortalDto,
  type BouncedEmailDto,
  type BouncedRecipientDto,
  type CancelScheduledJobRequest,
  type CheckoutAppendSessionDto,
  type CheckoutSessionDto,
  type ConnectorDto,
  type ContactDto,
  type CreateAliasRequest,
  type CreateAppendSession,
  type CreateConnectorRequest,
  type CreateContactOptions,
  type CreateDomainOptions,
  type CreateEmergencyAddressRequest,
  type CreateGroupOptions,
  type CreateInboxRequest,
  type CreateInboxRulesetOptions,
  type CreateNewInboxForwarderRequest,
  type CreateNewInboxReplierRequest,
  type CreateOrganizationSamlSettingsOptions,
  type CreateSubscriptionEndedOptions,
  type CreateTemplateOptions,
  type CreateUserFeedbackSubmissionOptions,
  type CreateWebhookOptions,
  type DeleteConnectorRequest,
  type DeleteInboxForwarderRequest,
  type DeleteInboxReplierRequest,
  type DeleteOrganizationUserRequest,
  type DeliveryStatusDto as DeliveryStatus,
  type DescribeMailServerDomainRequest,
  type DescribeMailServerDomainResult,
  type DNSLookupResults,
  type DomainDto,
  type DomainIssuesDto,
  type DomainPreview,
  type Email,
  type EmailPreview,
  type EmailVerificationResult,
  type EmergencyAddress,
  type EmergencyAddressDto,
  type EmptyResponseDto,
  type ExpirationDefaults,
  type ExpiredInboxDto,
  type ExportLink,
  type ExportOptions,
  type ForwardEmailOptions,
  type FractionLong,
  type GetAliasesRequest,
  type GetAliasThreadsRequest,
  type GetAllContactsRequest,
  type GetAllGroupsRequest,
  type GetAllMissedEmailsRequest,
  type GetAllScheduledJobsRequest,
  type GetAllSentTrackingPixelsRequest,
  type GetAllTemplatesRequest,
  type GetAllTrackingPixelsRequest,
  type GetAllUnknownMissedEmailsRequest,
  type GetAllWebhookResultsRequest,
  type GetAllWebhooksRequest,
  type GetAttachmentsRequest,
  type GetBouncedEmailsRequest,
  type GetBouncedRecipientsRequest,
  type GetComplaintsRequest,
  type GetConnectorRequest,
  type GetConnectorsRequest,
  type GetDnsLookupRequest,
  type GetDomainRequest,
  type GetExpiredInboxesRequest,
  type GetExpiredInboxRecordRequest,
  type GetExportLinkExportTypeEnum,
  type GetGroupWithContactsPaginatedRequest,
  GetInboxesSortEnum,
  type GetInboxForwarderEventsRequest,
  type GetInboxForwardersRequest,
  type GetInboxReplierEventsRequest,
  type GetInboxRepliersRequest,
  type GetInboxRulesetsRequest,
  type GetInboxSentEmailsRequest,
  type GetInboxWebhooksPaginatedRequest,
  type GetJsonSchemaForWebhookEventEventEnum,
  type GetMonthlyUsageChartsRequest,
  type GetPhoneNumberRequest,
  type GetPhoneNumbersRequest,
  type GetPhoneNumberWebhooksPaginatedRequest,
  type GetScheduledJobRequest,
  type GetScheduledJobsByInboxIdRequest,
  type GetSentDeliveryStatusesBySentIdRequest,
  type GetSentDeliveryStatusesRequest,
  type GetSentDeliveryStatusRequest,
  type GetSentEmailsRequest,
  type GetSentEmailTrackingPixelsRequest,
  type GetSmsMessagesPaginatedRequest,
  type GetStatisticsFractionFractionTypeEnum,
  type GetTestWebhookPayloadEventNameEnum,
  type GetUserEventsRequest,
  type GetUserStripeWebhookEventsRequest,
  type GetValidationRequestsRequest,
  type GetWebhookResultsRequest,
  type GrantAdminAccessToUserIdRequest,
  type GroupDto,
  type HasUserMadeActionDto,
  type ImapSmtpAccessDetails,
  type InboxDto as Inbox,
  type InboxForwarderDto,
  type InboxReplierDto,
  type InboxRulesetDto,
  type InviteOrganizationUserOptions,
  type InvoiceDto,
  type JSONSchemaDto,
  type LatestMessagesEmailDto,
  type LatestMessagesSmsDto,
  type ListInboxTrackingPixelsRequest,
  type MissedEmailDto as MissedEmail,
  type MonthlyUsageCharts,
  type Notification,
  type NotificationProjection,
  type OpenQuotesDto,
  type OptionalSubscriptionDto,
  type Organization,
  type OrganizationSamlServiceProviderData,
  type OrganizationSamlSettingsDto,
  type OtherAccountsDto,
  type PageAlias,
  type PageAttachmentEntity,
  type PageBouncedEmail,
  type PageBouncedRecipients,
  type PageComplaint,
  type PageConnector,
  type PageContactProjection,
  type PageDeliveryStatus,
  type PageEmailProjection,
  type PageEmailValidationRequest,
  type PageExpiredInboxRecordProjection,
  type PageGroupProjection,
  type PageInboxForwarderDto,
  type PageInboxForwarderEvents,
  type PageInboxProjection,
  type PageInboxReplierDto,
  type PageInboxReplierEvents,
  type PageInboxRulesetDto,
  type PageMissedEmailProjection,
  type PagePhoneNumberProjection,
  type PageSentEmailProjection,
  type PageSmsProjection,
  type PageStripeWebhookEvents,
  type PageTemplateProjection,
  type PageAliasThreadProjection as PageThreadProjection,
  type PageTrackingPixelProjection,
  type PageUnknownMissedEmailProjection,
  type PageUserEventProjections,
  type PageWebhookProjection,
  type PageWebhookResult,
  type PageZapierNewAttachment,
  type PageZapierNewContact,
  type PageZapierNewEmail,
  type PaymentFailure,
  type PhoneNumberDto,
  type PhonePlanDto,
  type PlanConfig,
  type PlanDisplays,
  type ProductPhonePlanDisplays,
  type QuoteDto,
  type QuoteInvoiceDto,
  type QuoteParams,
  type ReplyToEmailOptions,
  type ScheduledJobDto,
  type SendAccessLinkRequest,
  type SendEmailOptions,
  type SentEmailDto,
  type SentSmsDto,
  type SetPasswordOptions,
  type SmsDto,
  type StatsConstants,
  type SubscriptionDto,
  type TemplateDto,
  type TemplateProjection,
  type TrackingPixelDto,
  type UnreadCount,
  type UpdateConnectorRequest,
  type UpdateDomainRequest,
  type UpdateHasOnboardedOptions,
  type UpdateInboxForwarderRequest,
  type UpdateInboxOptions,
  type UpdateInboxReplierRequest,
  type UpdateOrganizationSamlSettingsOptions,
  type UpdateOrganizationUserOptions,
  type UpdatePrimaryEmailAddressOptions,
  type UsageDto,
  type User,
  type UserDto,
  type ValidateEmailAddressListResult,
  type ValidationDto,
  type VerifyEmailAddressRequest,
  type WebhookDto,
  type WebhookRedriveResult,
  type WebhookResultDto,
  type WebhookTestResult,
  type ZapierConnection,
  type ZapierGetNewAttachmentRecordsRequest,
  type ZapierGetNewContactRecordsRequest,
  type GetReplyForSmsMessageRequest,
  type ReplyToSmsMessageRequest,
  type ZapierGetNewEmailRecordsRequest,
  type ReplyForSms,
  type RedriveWebhookResultRequest,
  type DeleteValidationRequestRequest,
  type SubmitUnblockDefaultSenderRequestRequest,
  type SubmitUnfreezeRequestRequest,
  type SubmitUnfreezeRequestAnonymousRequest,
  type CheckEmailBodyFeatureSupportResults,
  type TestInboxRulesetSendingRequest,
  type TestInboxRulesetSendingResult,
  type TestInboxRulesetReceivingRequest,
  type TestInboxRulesetReceivingResult,
  type GetAllInboxForwarderEventsRequest,
  type InboxForwarderEventDto,
  type TestNewInboxForwarderRequest,
  type InboxForwarderTestResult,
  type CreateTemporaryAccessKeyOptionsEndpointEnum,
  type TemporaryAccessKeyDto,
  type UpdateOrganizationSettingsOptions,
  type OrganizationSettingsOptionalDto,
  type CreateOrganizationSettingsOptions,
  type GetOrganizationUsersPaginatedRequest,
  type PageOrganizationUser,
  type AccessAccountsDto,
  type SaveHasOnboardedFeatureFeatureEnum,
  type DeleteHasOnboardedFeatureFeatureEnum,
  type ProductUserPlanDisplays,
  type AddUserPlanOptions,
  type EmailPlanDto,
  type UserPlanDto,
  type PlanLimits,
  type GetOrganizationUserLimitsRequest,
  type GetThreadsPaginatedRequest,
  type CreateOnboardingSubmissionOptions,
  type GetAllInboxesOffsetPaginatedRequest,
  type GetEmailsOffsetPaginatedRequest,
  type GetComplaintRequest,
  type Complaint,
  type EmailValidationSubscription,
  type CreateUpgradeSessionWithExtras,
  type AttachmentEntity,
  type GetDnsLookupsRequest,
  type GetUsageRecordSeriesRequest,
  type GetEventAggregatesSeriesRequest,
  type GetUsageRecordUpdatesSeriesRequest,
  type SendForgotLinkRequest,
  type CheckEmailBodyResults,
  type UpdateSmtpAccessRequest,
  type ConnectorPlanDto,
  type CreateUpgradeSessionAddMissingExtras,
  type InboxPlanDto,
  type ProductPhoneNumberDisplays,
  type UserAddonsDto,
  type PageConnectorEvents,
  type CreateConnectorSyncSettingsRequest,
  type ConnectorSyncSettingsDto,
  type CreateConnectorImapConnectionRequest,
  type OptionalConnectorSyncSettingsDto,
  type GetConnectorImapConnectionRequest,
  type GetConnectorSmtpConnectionRequest,
  type OptionalConnectorSmtpConnectionDto,
  type OptionalConnectorImapConnectionDto,
  type ConnectorImapConnectionDto,
  type ConnectorSmtpConnectionDto,
  type DeleteConnectorImapConnectionRequest,
  type DeleteConnectorSmtpConnectionRequest,
  type CreateConnectorWithOptionsRequest,
  type GetAllConnectorEventsRequest,
  type ConnectorSmtpConnectionTestResult,
  type ConnectorImapConnectionTestResult,
  type TestConnectorSmtpConnectionOptionsRequest,
  type GetConnectorEventsRequest,
  type TestConnectorImapConnectionOptionsRequest,
  type UpdateImapAccessRequest,
  type GetAvailableDomainsRequest,
  type SignUpRequest,
  type ProductBatchedAddonPlanDisplays,
  type AppendProductAddonPlanRequest,
  type GetPlanDisplaysRequest,
  type UpdateConnectorSmtpConnectionRequest,
  type UpdateConnectorImapConnectionRequest,
  type GetConnectorEventRequest,
  type ConnectorEventDto,
  type GetConnectorSyncSettingsRequest,
  type GuestPortalDto,
  type GetGuestPortalRequest,
  type CreateGuestPortalRequest,
  type GuestPlanDto,
  type CreateGuestPortalUserRequest,
  type GuestPortalUserCreateDto,
  type GetGuestPortalUsersRequest,
  type PageGuestPortalUsers,
  type GetGuestPortalUserRequest,
  type GuestPortalUserDto,
  type GetAllGuestPortalUsersRequest,
  type GetGuestPortalUserByIdRequest,
  type GetEmailThreadsRequest,
  type GetEmailThreadRequest,
  type GetEmailThreadItemsRequest,
} from '@mailslurp/admin-sdk';
import { callOrThrow } from '../services/helpers';
import type { AdminClient } from './AdminClient';
import { getAdminClient } from './AdminClient';
import { getLogger } from '../services/getLogger';

const log = getLogger('api');

export class AdminService {
  readonly adminClient: AdminClient;

  constructor(apiKey: string, basePath: string) {
    this.adminClient = getAdminClient(apiKey, basePath);
  }

  /**
   * Portals
   */
  async getPortals(): Promise<GuestPortalDto[]> {
    return callOrThrow(() => this.adminClient.portalApi.getGuestPortalsRaw());
  }

  async getPortal(payload: GetGuestPortalRequest): Promise<GuestPortalDto> {
    return callOrThrow(() =>
      this.adminClient.portalApi.getGuestPortalRaw(payload),
    );
  }

  async createPortal(
    payload: CreateGuestPortalRequest,
  ): Promise<GuestPortalDto> {
    return callOrThrow(() =>
      this.adminClient.portalApi.createGuestPortalRaw(payload),
    );
  }

  async createGuest(
    payload: CreateGuestPortalUserRequest,
  ): Promise<GuestPortalUserCreateDto> {
    return callOrThrow(() =>
      this.adminClient.portalApi.createGuestPortalUserRaw(payload),
    );
  }

  async getGuests(
    payload: GetAllGuestPortalUsersRequest,
  ): Promise<PageGuestPortalUsers> {
    return callOrThrow(() =>
      this.adminClient.portalApi.getAllGuestPortalUsersRaw(payload),
    );
  }

  async getGuest(
    payload: GetGuestPortalUserByIdRequest,
  ): Promise<GuestPortalUserDto> {
    return callOrThrow(() =>
      this.adminClient.portalApi.getGuestPortalUserByIdRaw(payload),
    );
  }

  async deleteGuestPlans() {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteGuestPlansRaw(),
    );
  }

  async getGuestPlans(): Promise<Array<GuestPlanDto>> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getGuestPlansRaw(),
    );
  }

  async getProductGuestPlans(): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductGuestPlanDisplaysRaw({}),
    );
  }

  async checkEmailBody(emailId: string): Promise<CheckEmailBodyResults> {
    return callOrThrow(() =>
      this.adminClient.emailApi.checkEmailBodyRaw({ emailId }),
    );
  }

  async checkEmailBodyFeatureSupport(
    emailId: string,
  ): Promise<CheckEmailBodyFeatureSupportResults> {
    return callOrThrow(() =>
      this.adminClient.emailApi.checkEmailBodyFeatureSupportRaw({ emailId }),
    );
  }

  async getEmailValidation(emailId: string): Promise<ValidationDto> {
    return callOrThrow(() =>
      this.adminClient.emailApi.validateEmailRaw({ emailId }),
    );
  }

  async waitForRefreshSessionUpgrade(
    timeout: number,
  ): Promise<OptionalSubscriptionDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.waitForRefreshSessionUpgradeRaw({
        timeout,
      }),
    );
  }

  async refreshApiKey(): Promise<AccountDto> {
    return callOrThrow(() => this.adminClient.accountApi.refreshApiKeyRaw());
  }

  async updateSmtpSmtpAccess(opts: UpdateSmtpAccessRequest): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.updateSmtpAccessRaw(opts),
    );
  }

  async updateImapAccess(opts: UpdateImapAccessRequest): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.updateImapAccessRaw(opts),
    );
  }

  async refreshImapSmtpSecrets(): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.generateAndSetSmtpImapSecretsRaw(),
    );
  }

  /**
   * Repliers
   */
  async getInboxInboxRepliers(
    requestParams: GetInboxRepliersRequest,
  ): Promise<PageInboxReplierDto> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.getInboxRepliersRaw(requestParams),
    );
  }

  async getAllInboxRepliers(
    requestParams: GetInboxRepliersRequest,
  ): Promise<PageInboxReplierDto> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.getInboxRepliersRaw(requestParams),
    );
  }

  async getInboxReplierEvents(
    requestParams: GetInboxReplierEventsRequest,
  ): Promise<PageInboxReplierEvents> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.getInboxReplierEventsRaw(requestParams),
    );
  }

  async deleteInboxReplier(
    requestParams: DeleteInboxReplierRequest,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.deleteInboxReplierRaw(requestParams),
    );
  }

  async deleteAllInboxRepliers(): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.deleteInboxRepliersRaw({
        inboxId: undefined,
      }),
    );
  }

  /**
   * Temporary access keys
   */
  async getTemporaryAccessKey(
    endpoint: CreateTemporaryAccessKeyOptionsEndpointEnum,
  ): Promise<TemporaryAccessKeyDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.createTemporaryAccessKeyRaw({
        createTemporaryAccessKeyOptions: {
          endpoint: endpoint,
        },
      }),
    );
  }

  /**
   * Latest messages
   */
  async getLatestMessagesEmails(): Promise<LatestMessagesEmailDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getLatestMessagesEmailRaw(),
    );
  }

  async getLatestMessagesSms(): Promise<LatestMessagesSmsDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getLatestMessagesSmsRaw(),
    );
  }

  async replyToSms(options: ReplyToSmsMessageRequest): Promise<SentSmsDto> {
    return callOrThrow(() =>
      this.adminClient.smsApi.replyToSmsMessageRaw(options),
    );
  }

  async getReplyForSms(
    options: GetReplyForSmsMessageRequest,
  ): Promise<ReplyForSms> {
    return callOrThrow(() =>
      this.adminClient.smsApi.getReplyForSmsMessageRaw(options),
    );
  }

  /**
   * Metrics
   */
  async getMetricsNotifications() {
    return callOrThrow(() =>
      this.adminClient.accountApi.getDashMetricsNotificationsRaw(),
    );
  }

  async getMetricsReputation() {
    return callOrThrow(() =>
      this.adminClient.accountApi.getDashMetricsReputationRaw(),
    );
  }

  async getMetricsSubscription() {
    return callOrThrow(() =>
      this.adminClient.accountApi.getDashMetricsSubscriptionRaw(),
    );
  }

  /**
   * Connectors
   */
  async deleteAllInboxConnectors() {
    return callOrThrow(() =>
      this.adminClient.connectorApi.deleteAllConnectorRaw(),
    );
  }

  async getConnectorProviderSettings() {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorProviderSettingsRaw(),
    );
  }

  async getAllInboxConnectors(
    payload: GetConnectorsRequest,
  ): Promise<PageConnector> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorsRaw(payload),
    );
  }

  async getAllConnectorEventsPagination(
    payload: GetAllConnectorEventsRequest,
  ): Promise<PageConnectorEvents> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getAllConnectorEventsRaw(payload),
    );
  }

  async getConnectorEventsPagination(
    payload: GetConnectorEventsRequest,
  ): Promise<PageConnectorEvents> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorEventsRaw(payload),
    );
  }

  async testConnectorImapConnection(
    payload: TestConnectorImapConnectionOptionsRequest,
  ): Promise<ConnectorImapConnectionTestResult> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.testConnectorImapConnectionOptionsRaw(
        payload,
      ),
    );
  }

  async testConnectorSmtpConnection(
    payload: TestConnectorSmtpConnectionOptionsRequest,
  ): Promise<ConnectorSmtpConnectionTestResult> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.testConnectorSmtpConnectionOptionsRaw(
        payload,
      ),
    );
  }

  async updateInboxConnector(
    payload: UpdateConnectorRequest,
  ): Promise<ConnectorDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.updateConnectorRaw(payload),
    );
  }

  async getConnectorSmtpConnection(
    payload: GetConnectorSmtpConnectionRequest,
  ): Promise<OptionalConnectorSmtpConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorSmtpConnectionRaw(payload),
    );
  }

  async deleteConnectorSmtpConnection(
    payload: DeleteConnectorSmtpConnectionRequest,
  ) {
    return callOrThrow(() =>
      this.adminClient.connectorApi.deleteConnectorSmtpConnectionRaw(payload),
    );
  }

  async deleteConnectorImapConnection(
    payload: DeleteConnectorImapConnectionRequest,
  ) {
    return callOrThrow(() =>
      this.adminClient.connectorApi.deleteConnectorImapConnectionRaw(payload),
    );
  }

  async getConnectorEvent(
    payload: GetConnectorEventRequest,
  ): Promise<ConnectorEventDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorEventRaw(payload),
    );
  }

  async getConnectorSyncSettings(
    payload: GetConnectorSyncSettingsRequest,
  ): Promise<OptionalConnectorSyncSettingsDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorSyncSettingsRaw(payload),
    );
  }

  async getConnectorImapConnection(
    payload: GetConnectorImapConnectionRequest,
  ): Promise<OptionalConnectorImapConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorImapConnectionRaw(payload),
    );
  }

  async createConnectorImapConnection(
    payload: CreateConnectorImapConnectionRequest,
  ): Promise<ConnectorImapConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.createConnectorImapConnectionRaw(payload),
    );
  }

  async createConnectorSmtpConnection(
    payload: UpdateConnectorSmtpConnectionRequest,
  ): Promise<ConnectorSmtpConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.createConnectorSmtpConnectionRaw(payload),
    );
  }

  async updateConnectorImapConnection(
    payload: UpdateConnectorImapConnectionRequest,
  ): Promise<ConnectorImapConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.updateConnectorImapConnectionRaw(payload),
    );
  }

  async updateConnectorSmtpConnection(
    payload: UpdateConnectorSmtpConnectionRequest,
  ): Promise<ConnectorSmtpConnectionDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.updateConnectorSmtpConnectionRaw(payload),
    );
  }

  async createConnectorSyncSettings(
    payload: CreateConnectorSyncSettingsRequest,
  ): Promise<ConnectorSyncSettingsDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.createConnectorSyncSettingsRaw(payload),
    );
  }

  async createInboxConnectorWithOptions(
    payload: CreateConnectorWithOptionsRequest,
  ): Promise<ConnectorDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.createConnectorWithOptionsRaw(payload),
    );
  }

  async createInboxConnector(
    payload: CreateConnectorRequest,
  ): Promise<ConnectorDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.createConnectorRaw(payload),
    );
  }

  async getInboxConnector(payload: GetConnectorRequest): Promise<ConnectorDto> {
    return callOrThrow(() =>
      this.adminClient.connectorApi.getConnectorRaw(payload),
    );
  }

  async deleteInboxConnector(payload: DeleteConnectorRequest) {
    return callOrThrow(() =>
      this.adminClient.connectorApi.deleteConnectorRaw(payload),
    );
  }

  async getInboxReplier(id: string): Promise<InboxReplierDto> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.getInboxReplierRaw({ id }),
    );
  }

  async updateInboxReplier(
    options: UpdateInboxReplierRequest,
  ): Promise<InboxReplierDto> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.updateInboxReplierRaw(options),
    );
  }

  async createInboxReplier(
    options: CreateNewInboxReplierRequest,
  ): Promise<InboxReplierDto> {
    return callOrThrow(() =>
      this.adminClient.inboxReplierApi.createNewInboxReplierRaw(options),
    );
  }

  /**
   * Forwarders
   */
  async testNewInboxForwarder(
    requestParams: TestNewInboxForwarderRequest,
  ): Promise<InboxForwarderTestResult> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.testNewInboxForwarderRaw(
        requestParams,
      ),
    );
  }

  async getAllInboxForwarders(
    requestParams: GetInboxForwardersRequest,
  ): Promise<PageInboxForwarderDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getInboxForwardersRaw(requestParams),
    );
  }

  async getAllInboxForwarderEvents(
    requestParams: GetAllInboxForwarderEventsRequest,
  ): Promise<PageInboxForwarderEvents> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getAllInboxForwarderEventsRaw(
        requestParams,
      ),
    );
  }

  async getInboxForwarderEvents(
    requestParams: GetInboxForwarderEventsRequest,
  ): Promise<PageInboxForwarderEvents> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getInboxForwarderEventsRaw(
        requestParams,
      ),
    );
  }

  async getInboxInboxForwarders(
    requestParams: GetInboxForwardersRequest,
  ): Promise<PageInboxForwarderDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getInboxForwardersRaw(requestParams),
    );
  }

  async deleteInboxForwarder(
    requestParams: DeleteInboxForwarderRequest,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.deleteInboxForwarderRaw(requestParams),
    );
  }

  async deleteAllInboxForwarders(): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.deleteInboxForwardersRaw({
        inboxId: undefined,
      }),
    );
  }

  async deleteAllInboxInboxForwarders(inboxId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.deleteInboxForwardersRaw({ inboxId }),
    );
  }

  async getInboxForwarderEvent(id: string): Promise<InboxForwarderEventDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getForwarderEventRaw({ eventId: id }),
    );
  }

  async getInboxForwarder(id: string): Promise<InboxForwarderDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.getInboxForwarderRaw({ id }),
    );
  }

  async updateInboxForwarder(
    options: UpdateInboxForwarderRequest,
  ): Promise<InboxForwarderDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.updateInboxForwarderRaw(options),
    );
  }

  async createInboxForwarder(
    options: CreateNewInboxForwarderRequest,
  ): Promise<InboxForwarderDto> {
    return callOrThrow(() =>
      this.adminClient.inboxForwarderApi.createNewInboxForwarderRaw(options),
    );
  }

  /**
   * Rulesets
   */
  async getInboxInboxRulesets(
    requestParams: GetInboxRulesetsRequest,
  ): Promise<PageInboxRulesetDto> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.getInboxRulesetsRaw(requestParams),
    );
  }

  async testInboxRulesetReceiving(
    requestParams: TestInboxRulesetReceivingRequest,
  ): Promise<TestInboxRulesetReceivingResult> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.testInboxRulesetReceivingRaw(
        requestParams,
      ),
    );
  }

  async testInboxRulesetSending(
    requestParams: TestInboxRulesetSendingRequest,
  ): Promise<TestInboxRulesetSendingResult> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.testInboxRulesetSendingRaw(
        requestParams,
      ),
    );
  }

  async getAllInboxRulesets(
    requestParams: GetInboxRulesetsRequest,
  ): Promise<PageInboxRulesetDto> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.getInboxRulesetsRaw(requestParams),
    );
  }

  async deleteInboxRuleset(id: string): Promise<void> {
    return callOrThrow(async () =>
      this.adminClient.inboxRulesetApi.deleteInboxRulesetRaw({ id }),
    );
  }

  async deleteAllInboxRulesets(): Promise<void> {
    return callOrThrow(async () =>
      this.adminClient.inboxRulesetApi.deleteInboxRulesetsRaw({
        inboxId: undefined,
      }),
    );
  }

  async deleteAllInboxInboxRulesets(inboxId: string): Promise<void> {
    return await callOrThrow(() =>
      this.adminClient.inboxRulesetApi.deleteInboxRulesetsRaw({ inboxId }),
    );
  }

  async getInboxRuleset(id: string): Promise<InboxRulesetDto> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.getInboxRulesetRaw({ id }),
    );
  }

  async createInboxRuleset(payload: {
    inboxId: string | null;
    options: CreateInboxRulesetOptions;
  }): Promise<InboxRulesetDto> {
    return callOrThrow(() =>
      this.adminClient.inboxRulesetApi.createNewInboxRulesetRaw({
        inboxId: payload.inboxId ?? undefined,
        createInboxRulesetOptions: payload.options,
      }),
    );
  }

  /**
   * Attachments
   */
  async getAttachment(attachmentId: string): Promise<AttachmentEntity> {
    return callOrThrow(() =>
      this.adminClient.attachmentApi.getAttachmentRaw({ attachmentId }),
    );
  }

  async deleteAllAttachments() {
    return callOrThrow(() =>
      this.adminClient.attachmentApi.deleteAllAttachmentsRaw(),
    );
  }

  async deleteAttachment(attachmentId: string) {
    return callOrThrow(() =>
      this.adminClient.attachmentApi.deleteAttachmentRaw({ attachmentId }),
    );
  }

  async getAllAttachments(
    requestParams: GetAttachmentsRequest,
  ): Promise<PageAttachmentEntity> {
    return callOrThrow(() =>
      this.adminClient.attachmentApi.getAttachmentsRaw(requestParams),
    );
  }

  /**
   * Groups
   */
  async getGroup(groupId: string): Promise<GroupDto> {
    return callOrThrow(() =>
      this.adminClient.groupApi.getGroupRaw({ groupId }),
    );
  }

  async deleteGroup(groupId: string) {
    return callOrThrow(() =>
      this.adminClient.groupApi.deleteGroupRaw({ groupId }),
    );
  }

  async getAllGroups(
    requestParams: GetAllGroupsRequest,
  ): Promise<PageGroupProjection> {
    return callOrThrow(() =>
      this.adminClient.groupApi.getAllGroupsRaw(requestParams),
    );
  }

  async createGroup(groupOptions: CreateGroupOptions): Promise<GroupDto> {
    return callOrThrow(() =>
      this.adminClient.groupApi.createGroupRaw({
        createGroupOptions: groupOptions,
      }),
    );
  }

  async getEmailValidationRequestPagination(
    request: GetValidationRequestsRequest,
  ): Promise<PageEmailValidationRequest> {
    return callOrThrow(() =>
      this.adminClient.validateApi.getValidationRequestsRaw(request),
    );
  }

  async deleteAllEmailValidationRequests() {
    return callOrThrow(() =>
      this.adminClient.validateApi.deleteAllValidationRequestsRaw(),
    );
  }

  async deleteEmailValidationRequest(request: DeleteValidationRequestRequest) {
    return callOrThrow(() =>
      this.adminClient.validateApi.deleteValidationRequestRaw(request),
    );
  }

  /**
   * Contacts
   */
  async getContact(contactId: string): Promise<ContactDto> {
    return callOrThrow(() =>
      this.adminClient.contactApi.getContactRaw({ contactId }),
    );
  }

  async deleteContact(contactId: string) {
    return callOrThrow(() =>
      this.adminClient.contactApi.deleteContactRaw({ contactId }),
    );
  }

  async getAllContacts(
    requestParams: GetAllContactsRequest,
  ): Promise<PageContactProjection> {
    return callOrThrow(() =>
      this.adminClient.contactApi.getAllContactsRaw(requestParams),
    );
  }

  async createContact(
    contactOptions: CreateContactOptions,
  ): Promise<ContactDto> {
    return callOrThrow(() =>
      this.adminClient.contactApi.createContactRaw({
        createContactOptions: contactOptions,
      }),
    );
  }

  /**
   * Templates
   */
  async getTemplate(templateId: string): Promise<TemplateDto> {
    return callOrThrow(() =>
      this.adminClient.templateApi.getTemplateRaw({ templateId }),
    );
  }

  async deleteTemplate(templateId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.templateApi.deleteTemplateRaw({ templateId }),
    );
  }

  async getTemplates(): Promise<TemplateProjection[]> {
    return callOrThrow(() => this.adminClient.templateApi.getTemplatesRaw());
  }

  async getAllTemplates(
    requestParams: GetAllTemplatesRequest,
  ): Promise<PageTemplateProjection> {
    return callOrThrow(() =>
      this.adminClient.templateApi.getAllTemplatesRaw(requestParams),
    );
  }

  async createTemplate(
    templateOptions: CreateTemplateOptions,
  ): Promise<TemplateDto> {
    return callOrThrow(() =>
      this.adminClient.templateApi.createTemplateRaw({
        createTemplateOptions: templateOptions,
      }),
    );
  }

  /**
   * Zapier
   */
  async getZapierNewContactRecords(
    requestParams: ZapierGetNewContactRecordsRequest,
  ): Promise<PageZapierNewContact> {
    return callOrThrow(() =>
      this.adminClient.integrationApi.zapierGetNewContactRecordsRaw(
        requestParams,
      ),
    );
  }

  async getZapierNewEmailRecords(
    requestParams: ZapierGetNewEmailRecordsRequest,
  ): Promise<PageZapierNewEmail> {
    return callOrThrow(() =>
      this.adminClient.integrationApi.zapierGetNewEmailRecordsRaw(
        requestParams,
      ),
    );
  }

  async getZapierNewAttachmentRecords(
    requestParams: ZapierGetNewAttachmentRecordsRequest,
  ): Promise<PageZapierNewAttachment> {
    return callOrThrow(() =>
      this.adminClient.integrationApi.zapierGetNewAttachmentRecordsRaw(
        requestParams,
      ),
    );
  }

  async getZapierConnection(): Promise<ZapierConnection> {
    return callOrThrow(() =>
      this.adminClient.integrationApi.zapierHasUserConnectionRaw(),
    );
  }

  async deleteZapierConnection() {
    return callOrThrow(() =>
      this.adminClient.integrationApi.zapierDeleteUserConnectionRaw(),
    );
  }

  /**
   * Webhooks
   */
  async createWebhook(
    inboxId: string | null,
    webhookOptions: CreateWebhookOptions,
    phoneId: string | null,
  ): Promise<WebhookDto> {
    return callOrThrow(() =>
      phoneId
        ? this.adminClient.webhookApi.createWebhookForPhoneNumberRaw({
            createWebhookOptions: webhookOptions,
            phoneNumberId: phoneId,
          })
        : inboxId
          ? this.adminClient.webhookApi.createWebhookRaw({
              inboxId,
              createWebhookOptions: webhookOptions,
            })
          : this.adminClient.webhookApi.createAccountWebhookRaw({
              createWebhookOptions: webhookOptions,
            }),
    );
  }

  async deleteAllWebhooks(): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.deleteAllWebhooksRaw({}),
    );
  }

  async getWebhookJsonSchemaForEvent(
    event: GetJsonSchemaForWebhookEventEventEnum,
  ): Promise<JSONSchemaDto> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getJsonSchemaForWebhookEventRaw({
        event,
      }),
    );
  }

  async getWebhookJsonSchema(webhookId: string): Promise<JSONSchemaDto> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getJsonSchemaForWebhookPayloadRaw({
        webhookId,
      }),
    );
  }

  async getWebhookPayloadForEvent(
    eventName: GetTestWebhookPayloadEventNameEnum,
  ): Promise<AbstractWebhookPayload> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getTestWebhookPayloadRaw({
        eventName,
      }),
    );
  }

  async getWebhookPayload(webhookId: string): Promise<AbstractWebhookPayload> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getTestWebhookPayloadForWebhookRaw({
        webhookId,
      }),
    );
  }

  async testWebhook(webhookId: string): Promise<WebhookTestResult> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.sendTestDataRaw({ webhookId }),
    );
  }

  async getWebhook(webhookId: string): Promise<WebhookDto> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getWebhookRaw({ webhookId }),
    );
  }

  async getWebhookResult(webhookResultId: string): Promise<WebhookResultDto> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getWebhookResultRaw({ webhookResultId }),
    );
  }

  async redriveAllFailedWebhooks() {
    return callOrThrow(() =>
      this.adminClient.webhookApi.redriveAllWebhookResultsRaw(),
    );
  }

  async getWebhookResults(
    requestParams: GetWebhookResultsRequest,
  ): Promise<PageWebhookResult> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getWebhookResultsRaw(requestParams),
    );
  }

  async redriveWebhookResult(
    params: RedriveWebhookResultRequest,
  ): Promise<WebhookRedriveResult> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.redriveWebhookResultRaw(params),
    );
  }

  async getAllWebhookResults(
    requestParams: GetAllWebhookResultsRequest,
  ): Promise<PageWebhookResult> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getAllWebhookResultsRaw(requestParams),
    );
  }

  async deleteWebhook(
    inboxId: string | null,
    webhookId: string,
  ): Promise<void> {
    return callOrThrow(() =>
      inboxId
        ? this.adminClient.webhookApi.deleteWebhookRaw({ inboxId, webhookId })
        : this.adminClient.webhookApi.deleteWebhookByIdRaw({ webhookId }),
    );
  }

  async getPhoneWebhooksPagination(
    request: GetPhoneNumberWebhooksPaginatedRequest,
  ): Promise<PageWebhookProjection> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getPhoneNumberWebhooksPaginatedRaw(request),
    );
  }

  async getInboxWebhooksPagination(
    request: GetInboxWebhooksPaginatedRequest,
  ): Promise<PageWebhookProjection> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getInboxWebhooksPaginatedRaw(request),
    );
  }

  async getInboxWebhooks(inboxId: string): Promise<WebhookDto[]> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getWebhooksRaw({ inboxId }),
    );
  }

  async getAllWebhooks(
    requestParams: GetAllWebhooksRequest,
  ): Promise<PageWebhookProjection> {
    return callOrThrow(() =>
      this.adminClient.webhookApi.getAllWebhooksRaw(requestParams),
    );
  }

  /**
   * Plan
   */
  async getPlans(): Promise<PlanConfig[]> {
    return callOrThrow(() => this.adminClient.subscriptionApi.getPlansRaw());
  }

  async getProductEmailValidation(
    countryCode?: string,
  ): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductEmailValidationDisplaysRaw({
        countryCode,
      }),
    );
  }

  async getPlanDisplays(
    request: GetPlanDisplaysRequest,
  ): Promise<PlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getPlanDisplaysRaw(request),
    );
  }

  async getPhonePlansAvailability() {
    return callOrThrow(() =>
      this.adminClient.phoneApi.getPhonePlansAvailabilityRaw(),
    );
  }

  async getAccount(): Promise<AccountDto> {
    return callOrThrow(() => this.adminClient.accountApi.getAccountRaw());
  }

  async getOtherAccounts(): Promise<OtherAccountsDto> {
    return callOrThrow(() => this.adminClient.accountApi.getOtherAccountsRaw());
  }

  async getAccounts(emailAddress: string): Promise<AccessAccountsDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUserAccountsRaw({
        emailAddress,
      }),
    );
  }

  async remindOrganizations(emailAddress: string) {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.remindOrganizationsRaw({
        remindOrganizationsOptions: { emailAddress },
      }),
    );
  }

  /**
   * Onboarding
   */

  async createOnboardingSubmission(options: CreateOnboardingSubmissionOptions) {
    return callOrThrow(() =>
      this.adminClient.accountApi.createOnboardingSubmissionRaw({
        createOnboardingSubmissionOptions: options,
      }),
    );
  }

  async updateHasOnboarded(
    options: UpdateHasOnboardedOptions,
  ): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.updateHasOnboardedRaw({
        updateHasOnboardedOptions: options,
      }),
    );
  }

  async getOnboardedFeatures() {
    return callOrThrow(() =>
      this.adminClient.accountApi.hasOnboardedFeaturesRaw(),
    );
  }

  async deleteOnboardedFeature(feature: DeleteHasOnboardedFeatureFeatureEnum) {
    return callOrThrow(() =>
      this.adminClient.accountApi.deleteHasOnboardedFeatureRaw({
        feature,
      }),
    );
  }

  async deleteAllOnboardedFeatures() {
    return callOrThrow(() =>
      this.adminClient.accountApi.deleteAllHasOnboardedFeaturesRaw(),
    );
  }

  async deleteAllOnboarding() {
    return callOrThrow(() =>
      this.adminClient.accountApi.deleteAllOnboardingRaw(),
    );
  }

  async updateOnboardedFeature(feature: SaveHasOnboardedFeatureFeatureEnum) {
    return callOrThrow(() =>
      this.adminClient.accountApi.saveHasOnboardedFeatureRaw({
        feature: feature,
      }),
    );
  }

  async createOrganization(name: string, slug: string): Promise<Organization> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.createOrganizationRaw({
        createOrganizationOptions: { name, slug },
      }),
    );
  }

  async getOrganizationSamlSettings(
    slug: string,
  ): Promise<OrganizationSamlSettingsDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationSamlSettingsRaw({
        slug,
      }),
    );
  }

  async getOrganization(slug: string): Promise<Organization> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationRaw({ slug }),
    );
  }

  async getOrganizationUsersPaginated(
    payload: GetOrganizationUsersPaginatedRequest,
  ): Promise<PageOrganizationUser> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationUsersPaginatedRaw(
        payload,
      ),
    );
  }

  async getOrganizationUsers(slug: string): Promise<User[]> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationUsersRaw({
        slug,
      }),
    );
  }

  // sign up

  async getImapSmtpAccess(
    inboxId: string | undefined,
  ): Promise<ImapSmtpAccessDetails> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getImapSmtpAccessRaw({
        inboxId,
      }),
    );
  }

  async sendAccessLink(
    organizationSlug: string,
    emailAddress: string,
    redirectDevUrl?: boolean,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.sendOrganizationUserAccessLinkRaw({
        organizationUserAccessLinkOptions: { emailAddress },
        slug: organizationSlug,
        redirectDevUrl,
      }),
    );
  }

  async verifySoloUser(
    challengeCode: string,
    captchaToken: string | undefined,
  ): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.verifyForUserRaw({
        challengeOptions: { challengeCode, captchaToken },
      }),
    );
  }

  async sendInboxTestEmail(inboxId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.sendTestEmailRaw({ inboxId }),
    );
  }

  async setInboxFavourite(inboxId: string, state: boolean): Promise<Inbox> {
    return await this.adminClient.inboxApi.setInboxFavourited({
      inboxId,
      setInboxFavouritedOptions: { state },
    });
  }

  async updateAddNewContacts(value: boolean): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.updateAddNewContactsRaw({
        updateAddNewContactsOptions: { autoAddNewContacts: value },
      }),
    );
  }

  async updateAccountEmail(newEmailAddress: string): Promise<UserDto> {
    const params: UpdatePrimaryEmailAddressOptions = {
      newEmailAddress,
    };
    return callOrThrow(() =>
      this.adminClient.accountApi.updatePrimaryEmailAddressRaw({
        updatePrimaryEmailAddressOptions: params,
      }),
    );
  }

  /**
   * Delete validation product
   */
  async deleteProductValidation(): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteProductValidationRaw(),
    );
  }

  async createAppendProductNumberSession(
    addPhoneNumberOptions: AddPhoneNumberOptions,
  ): Promise<CheckoutAppendSessionDto> {
    return await callOrThrow(() =>
      this.adminClient.subscriptionApi.appendProductPhoneNumberRaw({
        addPhoneNumberOptions,
      }),
    );
  }

  async createAppendProductAddonPlanSession(
    requestParameters: AppendProductAddonPlanRequest,
  ): Promise<CheckoutAppendSessionDto> {
    return await callOrThrow(() =>
      this.adminClient.subscriptionApi.appendProductAddonPlanRaw(
        requestParameters,
      ),
    );
  }

  async createAppendProductUserPlanSession(
    addUserPlanOptions: AddUserPlanOptions,
  ): Promise<CheckoutAppendSessionDto> {
    return await callOrThrow(() =>
      this.adminClient.subscriptionApi.appendProductUserPlanRaw({
        addUserPlanOptions,
      }),
    );
  }

  async createAppendProductPhonePlanSession(
    addPhonePlanOptions: AddPhonePlanOptions,
  ): Promise<CheckoutAppendSessionDto> {
    return await callOrThrow(() =>
      this.adminClient.subscriptionApi.appendProductPhonePlanRaw({
        addPhonePlanOptions,
      }),
    );
  }

  async getOpenQuotes(): Promise<OpenQuotesDto> {
    return await callOrThrow(() =>
      this.adminClient.subscriptionApi.getOpenQuotesRaw(),
    );
  }

  async getUserLimits(): Promise<PlanLimits> {
    const userLimits = await callOrThrow(() =>
      this.adminClient.subscriptionApi.getUserLimitsRaw(),
    );
    return userLimits;
  }

  async getAllQuotes(): Promise<AllQuotesDto> {
    const quoteDto: AllQuotesDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.getAllQuotesRaw(),
    );
    return quoteDto;
  }

  async getQuoteInvoice(id: string): Promise<QuoteInvoiceDto> {
    const quoteDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.getQuoteInvoiceRaw({ quoteId: id }),
    );
    return quoteDto;
  }

  async cancelQuote(id: string): Promise<QuoteDto> {
    const quoteDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.cancelQuoteRaw({ quoteId: id }),
    );
    return quoteDto;
  }

  async acceptQuote(id: string): Promise<QuoteDto> {
    const quoteDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.acceptQuoteRaw({ quoteId: id }),
    );
    return quoteDto;
  }

  async getQuote(id: string): Promise<QuoteDto> {
    const quoteDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.getQuoteRaw({ quoteId: id }),
    );
    return quoteDto;
  }

  async getQuoteParams(): Promise<QuoteParams> {
    const quoteDto: QuoteParams = await callOrThrow(() =>
      this.adminClient.subscriptionApi.getQuoteParamsRaw(),
    );
    return quoteDto;
  }

  /**
   * Add a product to an existing subscription using stripe checkout
   * @param createAppendSession
   */
  async createCheckoutAppendSession(
    createAppendSession: CreateAppendSession,
  ): Promise<CheckoutAppendSessionDto> {
    const checkoutSessionDto: CheckoutAppendSessionDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.createAppendSessionRaw({
        createAppendSession,
      }),
    );
    return checkoutSessionDto;
  }

  /**
   * Create new subscription using stripe checkout
   * @param createUpgradeSession
   */
  async createCheckoutSession(
    createUpgradeSession: CreateUpgradeSessionWithExtras,
  ): Promise<CheckoutSessionDto> {
    const checkoutSessionDto: CheckoutSessionDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.createUpgradeSessionWithExtrasRaw({
        createUpgradeSessionWithExtras: createUpgradeSession,
      }),
    );
    return checkoutSessionDto;
  }

  async createCheckoutSessionAddMissingExtras(
    createUpgradeSession: CreateUpgradeSessionAddMissingExtras,
  ): Promise<CheckoutSessionDto> {
    const checkoutSessionDto: CheckoutSessionDto = await callOrThrow(() =>
      this.adminClient.subscriptionApi.createUpgradeSessionAddMissingExtrasRaw({
        createUpgradeSessionAddMissingExtras: createUpgradeSession,
      }),
    );
    return checkoutSessionDto;
  }

  async loginPassword(
    emailAddress: string,
    password: string,
  ): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUserForPasswordRaw({
        emailAddress,
        password,
      }),
    );
  }

  async forgotLink(
    emailAddress: string,
    preventMultipleUsers?: boolean,
  ): Promise<void> {
    return callOrThrow(() => {
      const opts: SendForgotLinkRequest = {
        accessOptions: {
          emailAddress,
        },
        preventNonExistingUsers: true,
        preventMultipleAccounts: preventMultipleUsers,
      };
      return this.adminClient.accountApi.sendForgotLinkRaw(opts);
    });
  }

  /**
   * Try send login link but reject existing users
   */
  async loginLink(
    emailAddress: string,
    preventMultipleUsers?: boolean,
  ): Promise<void> {
    return callOrThrow(() => {
      const opts: SendAccessLinkRequest = {
        accessOptions: {
          emailAddress,
        },
        preventNonExistingUsers: true,
        preventMultipleAccounts: preventMultipleUsers,
        isSignUp: false,
      };
      return this.adminClient.accountApi.sendAccessLinkRaw(opts);
    });
  }

  async signUp(params: SignUpRequest): Promise<UserDto> {
    return callOrThrow(() => this.adminClient.accountApi.signUpRaw(params));
  }

  // sign up solo
  async sendAccessLinkToSoloUser(
    emailAddress: string,
    redirectDevUrl: boolean | undefined,
    requestedPlanId: string | undefined,
    preventMultipleUsers?: boolean,
    isSignUp?: boolean,
  ): Promise<void> {
    const params: SendAccessLinkRequest = {
      accessOptions: { emailAddress },
      redirectDevUrl,
      requestedPlanId,
      preventMultipleAccounts: preventMultipleUsers,
      isSignUp: isSignUp ?? false,
    };
    return callOrThrow(() =>
      this.adminClient.accountApi.sendAccessLinkRaw(params),
    );
  }

  async verifyOrganizationUser(
    organizationSlug: string,
    challengeCode: string,
  ): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.verifyOrganizationUserAccessChallengeRaw(
        {
          organizationVerifyAccessOptions: { challengeCode },
          slug: organizationSlug,
        },
      ),
    );
  }

  async updateOrganizationUser(
    organizationId: string,
    userId: string,
    updateOrganizationUserOptions: UpdateOrganizationUserOptions,
  ): Promise<User> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.updateOrganizationUserRaw({
        slug: organizationId,
        userId,
        updateOrganizationUserOptions,
      }),
    );
  }

  async inviteOrganizationUser(
    organizationId: string,
    inviteOrganizationUserOptions: InviteOrganizationUserOptions,
  ): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.inviteOrganizationUserRaw({
        inviteOrganizationUserOptions,
        slug: organizationId,
      }),
    );
  }

  async getOrganizationSamlServiceProviderData(
    slug: string,
  ): Promise<OrganizationSamlServiceProviderData> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationSamlServiceProviderDataRaw(
        { slug },
      ),
    );
  }

  async deleteAllOrganizationsAndUsers(): Promise<void> {
    return callOrThrow(() => this.adminClient.organizationsApi.deleteAllRaw());
  }

  async deleteOrganization(organizationSlug: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.deleteOrganizationRaw({
        slug: organizationSlug,
      }),
    );
  }

  async deleteOrganizationUser(
    requestParams: DeleteOrganizationUserRequest,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.deleteOrganizationUserRaw(
        requestParams,
      ),
    );
  }

  async getOrganizations(): Promise<Organization[]> {
    log('Get organizations');
    const { organizations } = await callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationsRaw(),
    );
    return organizations;
  }

  /**
   * User events
   */
  async getUserEvents(
    params: GetUserEventsRequest,
  ): Promise<PageUserEventProjections> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUserEventsRaw(params),
    );
  }

  /**
   * Call raw endpoint and handle 404 and convert to null
   */
  async getSubscription(): Promise<SubscriptionDto | null> {
    const subOption = await callOrThrow<OptionalSubscriptionDto>(() =>
      this.adminClient.subscriptionApi.getSubscriptionOptionalRaw(),
    );
    return subOption.subscription ?? null;
  }

  async getStatsConstants(): Promise<StatsConstants> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getStatsConstantsRaw(),
    );
  }

  async getUsageCharts(
    requestParams: GetMonthlyUsageChartsRequest,
  ): Promise<MonthlyUsageCharts> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getMonthlyUsageChartsRaw(requestParams),
    );
  }

  async getStatsFraction(
    fractionType: GetStatisticsFractionFractionTypeEnum,
  ): Promise<FractionLong> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getStatisticsFractionRaw({
        fractionType,
      }),
    );
  }

  async getStats(): Promise<UsageDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getStatisticsRaw(),
    );
  }

  async deleteUser() {
    // TODO submit ended sub
    return callOrThrow(() => this.adminClient.accountApi.deleteUserRaw());
  }

  async submitUserFeedback(
    payload: CreateUserFeedbackSubmissionOptions,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.submitUserFeedbackRaw({
        createUserFeedbackSubmissionOptions: payload,
      }),
    );
  }

  async deleteSubscription(
    payload: CreateSubscriptionEndedOptions,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.endSubscriptionImmediatelyRaw({
        createSubscriptionEndedOptions: payload,
      }),
    );
  }

  async setPassword(password: string): Promise<void> {
    const setPasswordOptions: SetPasswordOptions = { password };
    return callOrThrow(() =>
      this.adminClient.accountApi.setPasswordRaw({ setPasswordOptions }),
    );
  }

  /**
   * Domains
   */
  async createDomain(options: CreateDomainOptions): Promise<DomainDto> {
    return callOrThrow(() =>
      this.adminClient.domainApi.createDomainRaw({
        createDomainOptions: options,
      }),
    );
  }

  async getDomain(payload: GetDomainRequest): Promise<DomainDto> {
    return callOrThrow(() => this.adminClient.domainApi.getDomainRaw(payload));
  }

  async updateDomain(options: UpdateDomainRequest): Promise<DomainDto> {
    return callOrThrow(() =>
      this.adminClient.domainApi.updateDomainRaw(options),
    );
  }

  async getDomains(): Promise<DomainPreview[]> {
    return callOrThrow(() => this.adminClient.domainApi.getDomainsRaw());
  }

  async getDomainIssues(): Promise<DomainIssuesDto> {
    return callOrThrow(() => this.adminClient.domainApi.getDomainIssuesRaw());
  }

  async deleteDomain(id: string): Promise<string[]> {
    return callOrThrow(() =>
      this.adminClient.domainApi.deleteDomainRaw({ id }),
    );
  }

  async addDomainCatchAll(id: string): Promise<DomainDto> {
    return callOrThrow(() =>
      this.adminClient.domainApi.addDomainWildcardCatchAllRaw({ id }),
    );
  }

  /**
   * Expired inbox records
   */
  async getExpiredInboxRecords(
    options: GetExpiredInboxesRequest,
  ): Promise<PageExpiredInboxRecordProjection> {
    return callOrThrow(() =>
      this.adminClient.expiredApi.getExpiredInboxesRaw(options),
    );
  }

  /**
   * Inbox
   */
  async deleteAllInboxes(): Promise<void> {
    return callOrThrow(() => this.adminClient.inboxApi.deleteAllInboxesRaw());
  }

  async updateInbox(id: string, options: UpdateInboxOptions): Promise<Inbox> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.updateInboxRaw({
        inboxId: id,
        updateInboxOptions: options,
      }),
    );
  }

  async getInboxes(includeInboxId?: string): Promise<Inbox[]> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getInboxesRaw({
        sort: GetInboxesSortEnum.DESC,
        include: includeInboxId ? [includeInboxId] : undefined,
      }),
    );
  }

  async getInbox(id: string): Promise<Inbox> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getInboxRaw({ inboxId: id }),
    );
  }

  async emptyInbox(id: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.commonApi.emptyInboxRaw({ inboxId: id }),
    );
  }

  async deleteInbox(id: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.deleteInboxRaw({ inboxId: id }),
    );
  }

  async getInboxTags(): Promise<string[]> {
    return callOrThrow(() => this.adminClient.inboxApi.getInboxTagsRaw());
  }

  async getAllInboxes(
    requestParams: GetAllInboxesOffsetPaginatedRequest,
  ): Promise<PageInboxProjection> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getAllInboxesOffsetPaginatedRaw(requestParams),
    );
  }

  /**
   * Email
   */
  async deleteAllEmails(): Promise<void> {
    return callOrThrow(() => this.adminClient.emailApi.deleteAllEmailsRaw());
  }

  async getUnreadEmailCount(): Promise<UnreadCount> {
    return callOrThrow(() =>
      this.adminClient.emailApi.getUnreadEmailCountRaw({}),
    );
  }

  /**
   * Schedule email jobs
   */
  async cancelScheduledJob(options: CancelScheduledJobRequest) {
    return callOrThrow(() =>
      this.adminClient.inboxApi.cancelScheduledJobRaw(options),
    );
  }

  async getScheduledJob(options: GetScheduledJobRequest) {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getScheduledJobRaw(options),
    );
  }

  async getScheduledJobs(options: GetAllScheduledJobsRequest) {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getAllScheduledJobsRaw(options),
    );
  }

  async getScheduledJobsForInbox(options: GetScheduledJobsByInboxIdRequest) {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getScheduledJobsByInboxIdRaw(options),
    );
  }

  async sendEmailWithSchedule(
    inboxId: string,
    sendEmailOptions: SendEmailOptions,
    timestamp: Date,
  ): Promise<ScheduledJobDto> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.sendWithScheduleRaw({
        sendAtTimestamp: timestamp.toISOString(),
        inboxId,
        sendEmailOptions,
        validateBeforeEnqueue: true,
      }),
    );
  }

  /**
   * Send with schedule
   */
  async sendEmailWithQueue(
    inboxId: string,
    sendEmailOptions: SendEmailOptions,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.sendEmailWithQueueRaw({
        inboxId,
        sendEmailOptions,
        validateBeforeEnqueue: true,
      }),
    );
  }

  async sendEmail(
    inboxId: string,
    sendEmailOptions: SendEmailOptions,
  ): Promise<SentEmailDto> {
    return callOrThrow(() => {
      log(`sendEmail ${inboxId} ${sendEmailOptions}`);
      return this.adminClient.inboxApi.sendEmailAndConfirmRaw({
        inboxId,
        sendEmailOptions,
      });
    });
  }

  async replyToEmail(
    emailId: string,
    replyToEmailOptions: ReplyToEmailOptions,
  ): Promise<SentEmailDto> {
    return callOrThrow(() => {
      log(`replyToEmail ${emailId} ${replyToEmailOptions}`);
      return this.adminClient.emailApi.replyToEmailRaw({
        emailId,
        replyToEmailOptions,
      });
    });
  }

  async markAsRead(emailId: string, read: boolean): Promise<EmailPreview> {
    return callOrThrow(async () =>
      this.adminClient.emailApi.markAsReadRaw({ emailId, read }),
    );
  }

  async getEmailThreadPagination(payload: GetEmailThreadsRequest) {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailThreadsRaw(payload),
    );
  }
  async getEmailThread(payload: GetEmailThreadRequest) {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailThreadRaw(payload),
    );
  }
  async getEmailThreadItems(payload: GetEmailThreadItemsRequest) {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailThreadItemsRaw(payload),
    );
  }

  async deleteEmail(emailId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.emailApi.deleteEmailRaw({ emailId }),
    );
  }

  async getEmail(emailId: string): Promise<Email> {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailRaw({ emailId }),
    );
  }

  async createInbox(requestParams: CreateInboxRequest): Promise<Inbox> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.createInboxRaw(requestParams),
    );
  }

  async createInboxWithDefaults(): Promise<Inbox> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.createInboxWithDefaultsRaw(),
    );
  }

  async getAllEmails(
    payload: GetEmailsOffsetPaginatedRequest,
  ): Promise<PageEmailProjection> {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailsOffsetPaginatedRaw(payload),
    );
  }

  async getAttachments(emailId: string): Promise<AttachmentMetaData[]> {
    return callOrThrow(() =>
      this.adminClient.emailApi.getEmailAttachmentsRaw({ emailId }),
    );
  }

  /**
   * Phone
   */
  async getUserPlans(): Promise<Array<UserPlanDto>> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getUserPlansRaw(),
    );
  }

  async deleteInboxPlans() {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteInboxPlansRaw(),
    );
  }

  async deleteEmailPlans() {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteEmailPlansRaw(),
    );
  }

  async deleteUserPlans() {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteUserPlansRaw(),
    );
  }

  async getInboxPlans(): Promise<Array<InboxPlanDto>> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getInboxPlansRaw(),
    );
  }

  async getEmailPlans(): Promise<Array<EmailPlanDto>> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getEmailPlansRaw(),
    );
  }

  async getProductInboxPlans(): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductInboxPlanDisplaysRaw({}),
    );
  }

  async getUserAddons(): Promise<UserAddonsDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getUserAddonsRaw(),
    );
  }

  async getProductEmailPlans(): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductEmailPlanDisplaysRaw({}),
    );
  }

  async getProductUserPlans(): Promise<ProductUserPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductUserPlanDisplaysRaw({}),
    );
  }

  async getProductPhonePlan(
    countryCode?: string,
  ): Promise<ProductPhonePlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductPhonePlanDisplaysRaw({
        countryCode,
      }),
    );
  }

  async getProductPhoneNumber(
    countryCode?: string,
  ): Promise<ProductPhoneNumberDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductPhoneNumberDisplaysRaw({
        countryCode,
      }),
    );
  }

  createEmergencyAddress(
    payload: CreateEmergencyAddressRequest,
  ): Promise<EmergencyAddress> {
    return callOrThrow(() =>
      this.adminClient.phoneApi.createEmergencyAddressRaw(payload),
    );
  }

  getEmergencyAddresses(): Promise<Array<EmergencyAddressDto>> {
    return callOrThrow(() =>
      this.adminClient.phoneApi.getEmergencyAddressesRaw(),
    );
  }

  getPhoneNumber(payload: GetPhoneNumberRequest): Promise<PhoneNumberDto> {
    return callOrThrow(() =>
      this.adminClient.phoneApi.getPhoneNumberRaw(payload),
    );
  }

  getPhoneNumbers(
    payload: GetPhoneNumbersRequest,
  ): Promise<PagePhoneNumberProjection> {
    return callOrThrow(() =>
      this.adminClient.phoneApi.getPhoneNumbersRaw(payload),
    );
  }

  getValidationPlans(): Promise<EmailValidationSubscription | null> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getEmailValidationSubscriptionOptionalRaw(),
    ).then((it) => it.subscription ?? null);
  }

  getProductAIPlans(): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductAIPlanDisplaysRaw({}),
    );
  }

  getProductConnectorPlans(): Promise<ProductBatchedAddonPlanDisplays> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getProductConnectorPlanDisplaysRaw({}),
    );
  }

  getAIPlans(): Promise<ConnectorPlanDto[]> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getConnectorPlansRaw(),
    );
  }

  getConnectorPlans(): Promise<ConnectorPlanDto[]> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getConnectorPlansRaw(),
    );
  }

  deleteAIPlans(): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteAIPlansRaw(),
    );
  }

  deleteConnectorPlans(): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteConnectorPlansRaw(),
    );
  }

  getPhonePlans(): Promise<PhonePlanDto[]> {
    return callOrThrow(() => this.adminClient.phoneApi.getPhonePlansRaw());
  }

  deletePhonePlans(): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deletePhonePlansRaw(),
    );
  }

  deletePhoneNumber(phoneNumberId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.phoneApi.deletePhoneNumberRaw({
        phoneNumberId,
      }),
    );
  }

  deleteSmsMessage(smsId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.smsApi.deleteSmsMessageRaw({
        smsId,
      }),
    );
  }

  getSmsMessage(smsId: string): Promise<SmsDto> {
    return callOrThrow(() =>
      this.adminClient.smsApi.getSmsMessageRaw({
        smsId,
      }),
    );
  }

  getSmsMessages(
    payload: GetSmsMessagesPaginatedRequest,
  ): Promise<PageSmsProjection> {
    return callOrThrow(() =>
      this.adminClient.smsApi.getSmsMessagesPaginatedRaw(payload),
    );
  }

  /**
   * Delivery status
   */
  async getDeliveryStatuses(
    requestParams: GetSentDeliveryStatusesRequest,
  ): Promise<PageDeliveryStatus> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentDeliveryStatusesRaw(requestParams),
    );
  }

  async getDeliveryStatusesBySentId(
    requestParams: GetSentDeliveryStatusesBySentIdRequest,
  ): Promise<PageDeliveryStatus> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentDeliveryStatusesBySentIdRaw(
        requestParams,
      ),
    );
  }

  async getDeliveryStatus(
    requestParams: GetSentDeliveryStatusRequest,
  ): Promise<DeliveryStatus> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentDeliveryStatusRaw(requestParams),
    );
  }

  /**
   * Complaints
   */
  async getComplaints(
    requestParams: GetComplaintsRequest,
  ): Promise<PageComplaint> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getComplaintsRaw(requestParams),
    );
  }

  /**
   * Bounce
   */
  async getBouncedEmail(id: string): Promise<BouncedEmailDto> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getBouncedEmailRaw({ id }),
    );
  }

  async getComplaint(requestParams: GetComplaintRequest): Promise<Complaint> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getComplaintRaw(requestParams),
    );
  }

  async getAllBouncedEmails(
    requestParams: GetBouncedEmailsRequest,
  ): Promise<PageBouncedEmail> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getBouncedEmailsRaw(requestParams),
    );
  }

  async getBouncedRecipient(id: string): Promise<BouncedRecipientDto> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getBouncedRecipientRaw({ id }),
    );
  }

  async getAllBouncedRecipients(
    requestParams: GetBouncedRecipientsRequest,
  ): Promise<PageBouncedRecipients> {
    return callOrThrow(() =>
      this.adminClient.bounceApi.getBouncedRecipientsRaw(requestParams),
    );
  }

  /**
   * Notifications
   */
  async getNotifications(): Promise<NotificationProjection[]> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getNotificationsRaw(),
    );
  }

  async markAllNotificationsRead(): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.markNotificationsSeenRaw(),
    );
  }

  async getNotification(id: string): Promise<Notification> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getFullNotificationMarkReadRaw({
        id,
      }),
    );
  }

  /**
   * Sent
   */
  async getSentForInbox(
    requestParams: GetInboxSentEmailsRequest,
  ): Promise<PageSentEmailProjection> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.getInboxSentEmailsRaw(requestParams),
    );
  }

  async getSentForUser(
    payload: GetSentEmailsRequest,
  ): Promise<PageSentEmailProjection> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentEmailsRaw({
        ...payload,
        inboxId: undefined,
      }),
    );
  }

  async getSent(sentId: string): Promise<SentEmailDto> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentEmailRaw({ id: sentId }),
    );
  }

  /**
   * Describe Mail Server
   */
  async mailServerDescribeDomain(
    request: DescribeMailServerDomainRequest,
  ): Promise<DescribeMailServerDomainResult> {
    return callOrThrow(() =>
      this.adminClient.mailServerApi.describeMailServerDomainRaw(request),
    );
  }

  async getDnsLookups(
    request: GetDnsLookupsRequest,
  ): Promise<DNSLookupResults> {
    return callOrThrow(() =>
      this.adminClient.mailServerApi.getDnsLookupsRaw(request),
    );
  }

  async getDnsLookup(request: GetDnsLookupRequest): Promise<DNSLookupResults> {
    return callOrThrow(() =>
      this.adminClient.mailServerApi.getDnsLookupRaw(request),
    );
  }

  /**
   * Verify the existence of an email address at a given mail server.
   */
  async verifyEmailAddress(
    requestParameters: VerifyEmailAddressRequest,
  ): Promise<EmailVerificationResult> {
    return callOrThrow(() =>
      this.adminClient.mailServerApi.verifyEmailAddressRaw(requestParameters),
    );
  }

  /**
   * Get stripe billing portal sessioned access link
   */
  async getBillingPortal(): Promise<BillingPortalDto | null> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.createBillingPortalSessionRaw(),
    );
  }

  async getPaymentFailures(): Promise<Array<PaymentFailure>> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.getPaymentFailuresRaw(),
    );
  }

  async getInvoices(): Promise<Array<InvoiceDto>> {
    return callOrThrow(() => this.adminClient.accountApi.getInvoicesRaw());
  }

  /**
   * Get contacts belonging to a group
   */
  async getGroupContacts(
    requestParams: GetGroupWithContactsPaginatedRequest,
  ): Promise<PageContactProjection> {
    return callOrThrow(() =>
      this.adminClient.groupApi.getGroupWithContactsPaginatedRaw(requestParams),
    );
  }

  async forwardEmail(
    emailId: string,
    options: ForwardEmailOptions,
  ): Promise<SentEmailDto> {
    return callOrThrow(() =>
      this.adminClient.emailApi.forwardEmailRaw({
        emailId,
        forwardEmailOptions: options,
      }),
    );
  }

  async getAlias(aliasId: string): Promise<AliasDto> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.getAliasRaw({ aliasId }),
    );
  }

  async getThread(threadId: string): Promise<ThreadProjection> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.getThreadRaw({ threadId }),
    );
  }

  async getThreadPagination(
    requestParams: GetAliasThreadsRequest,
  ): Promise<PageThreadProjection> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.getAliasThreadsRaw(requestParams),
    );
  }

  async getAllThreadsPagination(
    requestParams: GetThreadsPaginatedRequest,
  ): Promise<PageThreadProjection> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.getThreadsPaginatedRaw(requestParams),
    );
  }

  async getAliasPagination(
    requestParams: GetAliasesRequest,
  ): Promise<PageAlias> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.getAliasesRaw(requestParams),
    );
  }

  createAlias(createAliasRequest: CreateAliasRequest): Promise<AliasDto> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.createAliasRaw(createAliasRequest),
    );
  }

  deleteAlias(aliasId: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.aliasApi.deleteAliasRaw({ aliasId }),
    );
  }

  grantAdminAccess(
    payload: GrantAdminAccessToUserIdRequest,
  ): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.accountApi.grantAdminAccessToUserIdRaw(payload),
    );
  }

  getExpirationDefaults(): Promise<ExpirationDefaults> {
    return callOrThrow(() =>
      this.adminClient.expiredApi.getExpirationDefaultsRaw(),
    );
  }

  getUnknownMissedEmails(
    requestParams: GetAllUnknownMissedEmailsRequest,
  ): Promise<PageUnknownMissedEmailProjection> {
    return callOrThrow(() =>
      this.adminClient.missedEmailApi.getAllUnknownMissedEmailsRaw(
        requestParams,
      ),
    );
  }

  getMissedEmails(
    requestParams: GetAllMissedEmailsRequest,
  ): Promise<PageMissedEmailProjection> {
    return callOrThrow(() =>
      this.adminClient.missedEmailApi.getAllMissedEmailsRaw(requestParams),
    );
  }

  getMissedEmail(missedEmailId: string): Promise<MissedEmail> {
    return callOrThrow(() =>
      this.adminClient.missedEmailApi.getMissedEmailRaw({ missedEmailId }),
    );
  }

  verifySsoToken(token: string): Promise<UserDto> {
    return callOrThrow(() =>
      this.adminClient.ssoApi.validateLinkTokenRaw({
        sSOUserLink: { token },
      }),
    );
  }

  exportData(
    exportType: GetExportLinkExportTypeEnum,
    exportOptions: ExportOptions,
  ): Promise<ExportLink> {
    return callOrThrow(() =>
      this.adminClient.exportApi.getExportLinkRaw({
        exportType,
        exportOptions,
      }),
    );
  }

  getStripeWebhookEvents(
    payload: GetUserStripeWebhookEventsRequest,
  ): Promise<PageStripeWebhookEvents> {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUserStripeWebhookEventsRaw(payload),
    );
  }

  // tracking
  getTrackingPixel(payload: { id: string }): Promise<TrackingPixelDto> {
    return callOrThrow(() =>
      this.adminClient.trackingApi.getTrackingPixelRaw({ id: payload.id }),
    );
  }

  getAllTrackingPixels(
    payload: GetAllTrackingPixelsRequest,
  ): Promise<PageTrackingPixelProjection> {
    return callOrThrow(() =>
      this.adminClient.trackingApi.getAllTrackingPixelsRaw(payload),
    );
  }

  getAllSentEmailTrackingPixels(
    payload: GetAllSentTrackingPixelsRequest,
  ): Promise<PageTrackingPixelProjection> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getAllSentTrackingPixelsRaw(payload),
    );
  }

  getSentEmailTrackingPixels(
    payload: GetSentEmailTrackingPixelsRequest,
  ): Promise<PageTrackingPixelProjection> {
    return callOrThrow(() =>
      this.adminClient.sentEmailApi.getSentEmailTrackingPixelsRaw(payload),
    );
  }

  getInboxTrackingPixels(
    payload: ListInboxTrackingPixelsRequest,
  ): Promise<PageTrackingPixelProjection> {
    return callOrThrow(() =>
      this.adminClient.inboxApi.listInboxTrackingPixelsRaw(payload),
    );
  }

  getExpiredInbox(
    payload: GetExpiredInboxRecordRequest,
  ): Promise<ExpiredInboxDto> {
    return callOrThrow(() =>
      this.adminClient.expiredApi.getExpiredInboxRecordRaw(payload),
    );
  }

  deleteCustomer(
    createSubscriptionEndedOptions: CreateSubscriptionEndedOptions,
  ): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.subscriptionApi.deleteCustomerRaw({
        createSubscriptionEndedOptions,
      }),
    );
  }

  hasUserMadeAction(): Promise<HasUserMadeActionDto> {
    return callOrThrow(() => this.adminClient.accountApi.hasMadeActionRaw());
  }

  deleteOrganizationIcon(slug: string) {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.deleteOrganizationSettingsImageRaw({
        slug,
      }),
    );
  }

  getOrganizationSettings(
    slug: string,
  ): Promise<OrganizationSettingsOptionalDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationSettingsRaw({
        slug,
      }),
    );
  }

  logout() {
    return callOrThrow(() => this.adminClient.accountApi.logoutRaw());
  }

  createOrganizationSettings(
    slug: string,
    payload: CreateOrganizationSettingsOptions,
  ): Promise<OrganizationSettingsOptionalDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.createOrganizationSettingsRaw({
        createOrganizationSettingsOptions: payload,
        slug,
      }),
    );
  }

  createOrganizationSamlSettings(
    slug: string,
    payload: CreateOrganizationSamlSettingsOptions,
  ): Promise<OrganizationSamlSettingsDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.createOrganizationSamlSettingsRaw({
        createOrganizationSamlSettingsOptions: payload,
        slug,
      }),
    );
  }

  deleteOrganizationSettings(slug: string): Promise<EmptyResponseDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.deleteOrganizationSettingsRaw({
        slug,
      }),
    );
  }

  updateOrganizationSettings(
    slug: string,
    options: UpdateOrganizationSettingsOptions,
  ): Promise<OrganizationSettingsOptionalDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.updateOrganizationSettingsRaw({
        updateOrganizationSettingsOptions: options,
        slug,
      }),
    );
  }

  deleteOrganizationSamlSettings(slug: string): Promise<void> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.deleteOrganizationSamlSettingsRaw({
        slug,
      }),
    );
  }

  updateOrganizationSamlSettings(
    slug: string,
    options: UpdateOrganizationSamlSettingsOptions,
  ): Promise<OrganizationSamlSettingsDto> {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.updateOrganizationSamlSettingsRaw({
        updateOrganizationSamlSettingsOptions: options,
        slug,
      }),
    );
  }

  validateEmailList(
    payload: string[],
  ): Promise<ValidateEmailAddressListResult> {
    return callOrThrow(() =>
      this.adminClient.validateApi.validateEmailAddressListRaw({
        validateEmailAddressListOptions: {
          emailAddressList: payload,
        },
      }),
    );
  }

  deleteAllSms(phoneNumberId: string | undefined) {
    return callOrThrow(() =>
      this.adminClient.smsApi.deleteSmsMessagesRaw({
        phoneNumberId,
      }),
    );
  }

  sendTestSms(phoneNumberId: string, message: string) {
    return callOrThrow(() =>
      this.adminClient.phoneApi.testPhoneNumberSendSmsRaw({
        phoneNumberId,
        testPhoneNumberOptions: {
          message,
        },
      }),
    );
  }

  getOrganizationUserLimits(payload: GetOrganizationUserLimitsRequest) {
    return callOrThrow(() =>
      this.adminClient.organizationsApi.getOrganizationUserLimitsRaw(payload),
    );
  }

  submitUnblockDefaultSenderRequest(
    payload: SubmitUnblockDefaultSenderRequestRequest,
  ) {
    return callOrThrow(() =>
      this.adminClient.accountApi.submitUnblockDefaultSenderRequestRaw(payload),
    );
  }

  submitUnfreezeRequestRequest(payload: SubmitUnfreezeRequestRequest) {
    return callOrThrow(() =>
      this.adminClient.accountApi.submitUnfreezeRequestRaw(payload),
    );
  }

  submitUnfreezeRequestRequestAnony(
    payload: SubmitUnfreezeRequestAnonymousRequest,
  ) {
    return callOrThrow(() =>
      this.adminClient.accountApi.submitUnfreezeRequestAnonymousRaw(payload),
    );
  }

  getSeriesEventAggregates(payload: GetEventAggregatesSeriesRequest) {
    return callOrThrow(() =>
      this.adminClient.accountApi.getEventAggregatesSeriesRaw(payload),
    );
  }

  getSeriesUsage(payload: GetUsageRecordSeriesRequest) {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUsageRecordSeriesRaw(payload),
    );
  }

  getSeriesUsageRecordUpdates(payload: GetUsageRecordUpdatesSeriesRequest) {
    return callOrThrow(() =>
      this.adminClient.accountApi.getUsageRecordUpdatesSeriesRaw(payload),
    );
  }

  getDomainGroups(payload: GetAvailableDomainsRequest) {
    return callOrThrow(() =>
      this.adminClient.domainApi.getAvailableDomainsRaw(payload),
    );
  }
}
